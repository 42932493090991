<template>
  <base-view title="Comprobantes Fiscales" icon="file-text">
    <div>
      <check-authorization :requiresAuthorizations="['indice comprobantes']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Comprobantes Fiscales

            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <!-- Form -->
              <check-authorization
                :requiresAuthorizations="['crear comprobantes']"
                :overrideIf="$store.getters['facturaModule/isEditingResource']"
              >
                <!--comprobantes-form
                  :value="showForm || isEditingResource"
                  @input="showForm = $event"
                /-->
                <b-button @click="exportar" class="ml-3" size="sm" variant="success">
                  Exportar
                </b-button>
              </check-authorization>
              <!-- Search -->
              <search-bar
                placeholder="Buscar por folio fiscal, inversionista, empresa, departamento, proyecto o etapa"
                @filter="(filter) => this.filter = filter"></search-bar>
              <!-- Options -->
              <div class="dropdown no-caret">
                <button
                  class="btn btn-transparent-dark btn-icon dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
                  <a class="dropdown-item my-cursor" @click="getUsers(null)">
                    Recargar
                  </a>
                </div>
              </div>
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <x-select
              v-model="periodo"
              dispatchPath="periodoModule/getList"
              getterListPath="periodoModule/getDataList"
              :formatListWith="['id', 'descriptor']"
              defaultText="-- Selecciona un período --"
              noClearButton
              prependText="Período"
              class="mb-2"
            >
            </x-select>
            <comprobantes-list @on-got-item="showForm = true" v-if="periodo" :periodo="periodo" :filter="filter"></comprobantes-list>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import ComprobantesList from '@/components/Operaciones/Comprobantes/ComprobantesList'
import { mapActions, mapGetters } from 'vuex'
import SearchBar from '../../components/Common/Views/SearchBar.vue'

const STORE_MODULE = 'comprobantesModule'
const PERIODO_MODULE = 'periodoModule'

export default {
  name: 'ComprobantesMainView',

  components: {
    ComprobantesList,
    SearchBar
  },

  created () {
    this.init()
  },

  data () {
    return {
      hideButton: false,
      showForm: false,
      periodo: null,
      filter: ''
    }
  },

  computed: mapGetters(STORE_MODULE, ['isEditingResource']),

  methods: {
    ...mapActions(STORE_MODULE, ['getResources']),
    async init () {
      // Inicializamos secciones
      this.periodoActual()
    },
    async periodoActual () {
      // Obtenemos el período que corresponda al mes y año actual
      const result1 = await this.$store.dispatch(`${PERIODO_MODULE}/getPeriodoActual`)
      if (result1.error) {
        const {
          error,
          message
        } = result1

        this.$notify({
          error,
          message
        }, 'Rentas')
        return
      }
      console.log(result1.data.periodo)
      this.periodo = result1.data.periodo.id
    },
    async exportar () {
      console.log('exportar', this.periodo)
      const result = await this.$store.dispatch(`${STORE_MODULE}/exportar`,
        { periodo_id: this.periodo })
      await this.$onDownload(result, `comprobantes_${this.periodo.descriptor}.xlsx`)

      this.$notify({ message: `Exportación de comprobantes en el período ${this.periodo.descriptor}.` })
    }
  }
}
</script>

<style scoped>

</style>
