import { render, staticRenderFns } from "./ComprobantesDetalles.vue?vue&type=template&id=6aa59f96&scoped=true"
import script from "./ComprobantesDetalles.vue?vue&type=script&lang=js"
export * from "./ComprobantesDetalles.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aa59f96",
  null
  
)

export default component.exports