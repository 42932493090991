<template>
  <div>
    <!-- Table -->
    <b-pagination size="sm" align="fill"
                  v-if="(getTotalItems / perPage) > 1"
                  v-model="currentPage"
                  :total-rows="getTotalItems"
                  :per-page="perPage"
                  :disabled="!getAvailableList"
                  aria-controls="plantillas-contratos-table"
    ></b-pagination>
    <b-table
      id="facturasTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getResourceList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="['folio_fiscal', 'inversionista', 'empresa', 'departamentos', 'condominio', 'subcondominio']"
      @filtered="onFiltered"
      empty-filtered-text="No se encontraron comprobantes fiscales con el criterio de búsqueda escrito"
    >
      <template #cell(fecha)="row">
          <span class="text-sm text-center w-100" :class="{ 'text-muted': row.item.is_deleted }">
          {{ new Date(row.value).toLocaleDateString('es-MX', { year: 'numeric', month: 'numeric', day: 'numeric' }) }}
          </span>
      </template>

      <template #cell(folio_fiscal)="row">
        <filtered-row :value="row.value" :filter="filter"/>
      </template>

      <template #cell(inversionista)="row">
        <filtered-row :value="row.value" :filter="filter"/>
      </template>

      <template #cell(empresa)="row">
        <filtered-row :value="row.value" :filter="filter"/>
      </template>

      <template #cell(departamentos)="row">
        <filtered-row :value="row.value" :filter="filter"/>
      </template>

      <template #cell(condominio)="row">
        <filtered-row :value="row.value" :filter="filter"/>
      </template>

      <template #cell(subcondominio)="row">
        <filtered-row :value="row.value" :filter="filter"/>
      </template>

      <template #cell(folio_interno)="row">
        <div class="text-sm w-100">
          {{ row.value }}
        </div>
      </template>

      <template #cell(version)="row">
        <div class="text-sm w-100">
          {{ row.value }}
        </div>
      </template>

      <template #cell(importe)="row">
        <div class="text-sm w-100">
          {{ $formatNumber(row.value) }}
        </div>
      </template>

      <template #cell(verificado)="row">
        <div class="text-sm w-100">
          {{ row.value }}
        </div>
      </template>

      <template #cell(autorizado)="row">
        <div class="text-sm w-100">
          {{ row.value }}
        </div>
      </template>

      <template #cell(cancelado)="row">
        <div class="text-sm w-100">
          {{ row.value }}
        </div>
      </template>

      <template #cell(archivos)="row">
        <div class="text-sm w-100">
          <ul>
            <li v-for="archivo in row.item.archivos" v-bind:key="archivo.id">
              <b-link @click="onDownloadFile(archivo)">
                {{ archivo.filename }}
              </b-link>
            </li>
          </ul>
        </div>
      </template>

      <!-- Detalle -->
      <template #cell(show_validaciones)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Validaciones
        </b-button>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
          <u-d-controls
            :id="row.item.id"
            editPath="comprobantesModule/getResourceToEdit"
            deletePath="comprobantesModule/deleteResource"
            :editPermissions="['actualizar facturas']"
            :deletePermissions="['eliminar facturas']"
            hideEditAction
            @on-got-item="emitOnGotItem"
          ></u-d-controls>
        </div>
      </template>
      <template #cell(autorizar)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
        <div class="small d-flex ml-2">
            <div v-if="confirmAuthorization && row.item.id === idToAuthorize" class="d-flex justify-content-md-between">
              <!-- Cancelar -->
              <a class="link text-black mr-2" @click="resetAuthorization">Cancelar autorización</a>

              <!-- Confirmar -->
              <a class="link text-danger" @click="confirmAuthorizeRow">Confirmar autorización</a>
            </div>
            <div v-else class="d-sm-flex justify-content-end">
              <b-button
                v-if="row.item.autorizado !== 'Si'"
                class="btn-sm mr-3 mb-2 mb-sm-0 btn-outline-green"
                :disabled="row.item.autorizado === 'Si'"
                variant="white"
                @click="authorizeRow(row.item.id)">
                Autorizar
              </b-button>
            </div>
          </div>
        </div>
      </template>

      <!-- Acciones -->
      <template #row-details="row">
        <comprobantes-detalles :row="row"></comprobantes-detalles>
      </template>
    </b-table>
    <loading v-else></loading>

    <!--div v-if="isThereData">
      <paginator
        aria-controls="usersTable"
        :disabled="!getAvailableList"
        show-path="userModule/getUsers"
        :resource="getResourceList"
      ></paginator>
    </div-->
    <!-- End Table -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ComprobantesDetalles from './ComprobantesDetalles.vue'
import FilteredRow from '../../Common/Table/FilteredRow.vue'

const STORE_MODULE = 'comprobantesModule'

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

export default {
  name: 'ComprobantesList',

  components: {
    ComprobantesDetalles,
    FilteredRow
  },

  created () {
    this.submit()
  },

  props: {
    periodo: null,
    filter: {
      required: true
    }
  },

  data () {
    return {
      selectedId: null,

      isLoading: false,

      response: response(),

      idToAuthorize: null,

      confirmAuthorization: false,

      fields: [
        { key: 'fecha', label: 'Fecha' },
        { key: 'folio_fiscal', label: 'Folio Fiscal' },
        { key: 'inversionista', label: 'Inversionista' },
        { key: 'folio_interno', label: 'Folio Interno' },
        { key: 'departamentos', label: 'Departamentos' },
        { key: 'empresa', label: 'Empresa' },
        { key: 'condominio', label: 'Proyecto' },
        { key: 'subcondominio', label: 'Etapa' },
        { key: 'importe', label: 'Importe' },
        { key: 'verificado', label: 'Válido' },
        { key: 'autorizado', label: 'Autorizado' },
        { key: 'cancelado', label: 'Cancelado' },
        { key: 'archivos', label: 'Archivos' },
        { key: 'show_validaciones', label: 'Validaciones' },
        { key: 'acciones', label: 'Acciones' },
        { key: 'autorizar', label: 'Autorizar' }
      ],

      perPage: 10,

      currentPage: 1,

      totalRows: 0
    }
  },

  watch: {
    periodo: {
      immediate: true,
      handler (value) {
        this.submit()
      }
    }
  },

  computed: {
    ...mapGetters(STORE_MODULE, [
      'getResourceList',
      'getAvailableList',
      'isThereData',
      'getResource',
      'getTotalItems'
    ])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch(`${STORE_MODULE}/getResource`, [this.periodo])
      this.totalRows = this.getTotalItems

      if (error) this.$notify({ error, message }, 'Comprobantes Fiscales')
    },

    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    authorizeRow (id) {
      this.idToAuthorize = id
      this.confirmAuthorization = true
    },

    resetAuthorization () {
      this.confirmAuthorization = false
    },

    async confirmAuthorizeRow () {
      // realiza la confirmación de la autorización y notifica al usuario si se pudo realizar
      const { error, message } = await this.$store.dispatch(`${STORE_MODULE}/authorizeResource`, this.idToAuthorize)

      if (error) this.$notify({ error, message }, 'Autorización del comprobante')
      this.confirmAuthorization = false
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    },

    async onDownloadFile (archivo) {
      const result = await this.$store.dispatch('archivoModule/download', archivo.id)

      await this.$onDownload(result, archivo.filename)

      this.$notify({ message: `Descarga de archivo ${archivo.filename}` })
    }
  }
}
</script>

<style scoped>

</style>
