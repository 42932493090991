<template>
  <div>
    <b-modal
      v-model="modalSeleccionPeriodo"
      centered
      size="lg"
      scrollable
      dialog-class="x-modal-md"
    >
      <template #modal-title>Seleccionar período</template>
      <template #default>
        <b-overlay :show="settingPeriodo">
          <template #overlay>
            <div class="text-center">
              <loading message="Asignando período al concepto del comprobante..."></loading>
            </div>
          </template>
          <FormulateForm
            ref="selectPeriodoForm"
            name="selectPeriodoForm"
            v-model="formValues"
            :errors="response.errors"
            @submit="submitSelectPeriodoForm"
          >
            <div class="card">
              <div class="card-header">
                Seleccione el período del concepto
              </div>
              <div class="card-body">
                <b-row>
                  <b-col cols="12" md="12">
                    <FormulateInput
                      name="periodo_id"
                      validation="bail|required">
                      <template #element>
                        <x-form-group title="Período" required>
                          <x-select
                            v-model="formValues.periodo_id"
                            dispatchPath="periodoModule/getList"
                            getterListPath="periodoModule/getDataList"
                            :formatListWith="['id', 'descriptor']"
                            :disabled="isLoading"
                          >
                          </x-select>
                        </x-form-group>
                      </template>
                    </FormulateInput>
                  </b-col>
                </b-row>
              </div>
            </div>
          </FormulateForm>
        </b-overlay>
      </template>
      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :disabled="$refs.selectPeriodoForm ? $refs.selectPeriodoForm.hasErrors : false"
            :loading="isLoading"
            :isEditing="true"
            :isCreating="false"
            @on-cancel="resetSelectPeriodoForm"
            @on-create="submitSelectPeriodoForm"
            @on-update="submitSelectPeriodoForm"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="modalSeleccionDepartamento"
      centered
      size="lg"
      scrollable
      dialog-class="x-modal-md"
    >
      <template #modal-title>Seleccionar departamento</template>
      <template #default>
        <b-overlay :show="settingDepartamento">
          <template #overlay>
            <div class="text-center">
              <loading message="Asignando departamento al concepto del comprobante..."></loading>
            </div>
          </template>
          <FormulateForm
            ref="selectDepartamentoForm"
            name="selectDepartamentoForm"
            v-model="formValues"
            :errors="response.errors"
            @submit="submitSelectDepartamentoForm"
          >
            <div class="card">
              <div class="card-header">
                Seleccione el departamento del concepto
              </div>
              <div class="card-body">
                <b-row>
                  <b-col cols="12" md="12">
                    <FormulateInput
                      name="departamento_id"
                      validation="bail|required">
                      <template #element>
                        <x-form-group title="Departamento" required>
                          <x-select
                            v-model="formValues.departamento_id"
                            dispatchPath="inversionistaModule/getSelectDeptos"
                            getterListPath="inversionistaModule/getDeptosData"
                            :formatListWith="['id', 'identificacion']"
                            :disabled="isLoading"
                          >
                          </x-select>
                        </x-form-group>
                      </template>
                    </FormulateInput>
                  </b-col>
                </b-row>
              </div>
            </div>
          </FormulateForm>
        </b-overlay>
      </template>
      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :disabled="$refs.selectDepartamentoForm ? $refs.selectDepartamentoForm.hasErrors : false"
            :loading="isLoading"
            :isEditing="true"
            :isCreating="false"
            @on-cancel="resetSelectDepartamentoForm"
            @on-create="submitSelectDepartamentoForm"
            @on-update="submitSelectDepartamentoForm"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-model="modalUploadPdf"
      centered
      size="lg"
      scrollable
      hide-footer
      dialog-class="x-modal-md"
    >
      <template #modal-title>Importar PDF del comprobante</template>
      <template #default>
        <b-overlay :show="uploadingPdf">
          <template #overlay>
            <div class="text-center">
              <loading message="Adjuntando el archivo PDF al comprobante..."></loading>
            </div>
          </template>
          <b-form @submit.prevent="submitUploadPdf" class="momentosContables__form">
            <div class="flex-grow-1 d-flex align-items-center justify-content-center">
              <b-form-group>
                {{ /* En este caso solo se puede subir un archivo PDF */}}
                <b-form-file v-model="files" :state="Boolean(files.length)" multiple></b-form-file>
              </b-form-group>
              <div v-if="!isFormValid">
                <div>Errores:</div>
                <ul>
                  <li v-if="files.length > 1"><span class="text-danger">Solo puede seleccionar un archivo</span></li>
                  <li v-if="files.length === 1 && selectedFileExtension!=='pdf'"><span class="text-danger">La extensión del archivo debe ser 'pdf'</span></li>
                  <li v-if="files.length === 1 && selectedFileName!==xmlFileName"><span class="text-danger">El nombre del archivo debe coincidir con el archivo XML</span></li>
                </ul>
              </div>
            </div>

            <div class="my-2 d-flex">
              <slot name="btn-cancel-place" v-bind="{ clearForm }"></slot>

              <b-button
                type="submit"
                variant="success"
                class="ml-auto"
                size="sm"
                :disabled="!isFormValid"
              >
                Subir
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </template>
    </b-modal>

    <b-card
      class="mb-1"
      border-variant="primary"
      header-bg-variant="primary"
      header-text-variant="white"
      header="Validaciones aplicadas al comprobante fiscal"
      no-body
    >
      <b-card-body>
        <b-row class="mb-2">
          <b-col cols="12" md="12" sm="12">
            <b-overlay :show="loadingValidations">
              <template #overlay>
                <div class="text-center">
                  <loading message="Obteniendo validaciones, por favor espere..."></loading>
                </div>
              </template>

              <b-card
                :header="`Comprobante fiscal: ${filesNames}`"
                no-body
                border-variant="primary"
                header-text-variant="black"
                class="mb-4"
                style="height: 500px; overflow: auto;"
              >
                <b-row>
                  <b-col cols="1"></b-col>
                  <b-col cols="3">
                    <b-form-checkbox class="user-select-none" :v-model="!bfilter" switch @change="filter = filter === 'No' ? '' : 'No'">
                      <strong>{{ filter === '' ? 'Ocultar' : 'Ver todo' }}</strong>
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="2">
                    <div class="pt-1">
                      <strong>Válido: {{ isValid ? 'Si' : 'No' }}</strong>
                    </div>
                  </b-col>
                  <b-col cols="2">
                    <div class="pt-1">
                      <strong>Errores: {{ numErrores }}</strong>
                    </div>
                  </b-col>
                  <b-col cols="3">
                    <!--b-button  class="btn-sm btn-outline-success mr-3 mb-2 mb-sm-0"
                               size="sm"
                               @click="onValidateRow(row.item.id)"
                               variant="white">
                      Aplicar nuevamente las reglas
                    </b-button-->
                  </b-col>
                </b-row>
                <b-table
                  id="validationsTable"
                  :fields="fields"
                  :items="validations"
                  striped
                  bordered
                  responsive
                  hover
                  stacked="lg"
                  show-empty
                  small
                  :filter="filter"
                  :filter-function="filterFunction"
                >
                  <template #cell(parent)="row">
                      <span :class="'text-sm text-center w-100' + row.item.is_valid ? '':'text-danger'" >
                        {{ row.item.parent }}
                      </span>
                  </template>
                  <template #cell(estructura.nombre)="row">
                      <span :class="'text-sm text-center w-100' + row.item.is_valid ? '':'text-danger'" variant="danger">
                        {{ row.item.estructura.nombre }}
                      </span>
                  </template>
                  <template #cell(valor)="row">
                      <span :class="'text-sm text-center w-100' + row.item.is_valid ? '':'text-danger'" variant="danger">
                        {{ row.item.valor }}
                      </span>
                  </template>
                  <template #cell(reglas_comprobante)="row">
                    <div v-if="rowIsValid">
                      <div v-if="row.item.is_valid === 'No' && row.item.was_applied === 'Si'">
                        <ul>
                          <li v-for="regla in filterInvalidRules(row.item.reglas_comprobante)" :key="regla.id">
                            <i v-if="regla.is_valid" data-feather="edit" class="mt-1"></i>
                            <i v-else data-feather="x-square" class="mt-1"></i>
                            {{ regla.regla_estructura.regla.nombre }}: {{ regla.regla_estructura.regla.correccion }}
                            <ul v-if="regla.resultado">
                              <li>{{ regla.resultado }}</li>
                            </ul>
                            <ul v-if="regla.condicion">
                              <li>{{ regla.condicion }}</li>
                            </ul>
                            <div v-if="regla.regla_estructura.regla.clave === 'AttributePeriodoConceptoRule' && !regla.is_valid">
                              <b-button variant="primary" size="sm" v-b-tooltip.hover title="Seleccione manualmente el período del concepto" @click="onSelectPeriodo(row.item.id)">
                                Seleccionar Período
                              </b-button>
                            </div>
                            <div v-else-if="regla.regla_estructura.regla.clave === 'AttributePropiedadConceptoRule' && !regla.is_valid">
                              <b-button variant="primary" size="sm" v-b-tooltip.hover title="Seleccione manualmente el departamento del concepto" @click="onSelectDepartamento(row.item.id)">
                                Seleccionar Departamento
                              </b-button>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div v-else-if="row.item.is_required === 'Si'">
                        <ul>
                          <li>
                            Se requiere la presencia de este atributo en el comprobante
                          </li>
                        </ul>
                      </div>
                      {{/* Caso especial para permitir adjuntar el PDF o seleccionar de forma manual el período y departamento*/}}
                      <div v-if="row.item.estructura.nombre === 'Descripcion'">
                        <b-button
                          v-if="!pdfIsPresent"
                          variant="primary"
                          size="sm"
                          v-b-tooltip.hover
                          title="Adjunte el archivo PDF del comprobante para extraer la información del período y departamento o seleccione manualmente"
                          @click="onUploadPdf(row.item.id)"
                        >
                          Cargar PDF
                        </b-button>
                      </div>
                    </div>
                  </template>
                </b-table>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
        <b-card no-body>
          <b-button
            variant="white"
            size="sm"
            class="btn-outline-black"
            @click="row.toggleDetails"
          >
            Cerrar detalle
          </b-button>
        </b-card>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const STORE_MODULE = 'comprobantesModule'
const formValues = () => ({
  comprobante_id: '',
  concepto_id: '',
  periodo_id: '',
  departamento_id: '',
  archivo: []
})

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

export default {
  name: 'ComprobantesDetalles',

  data () {
    return {
      fields: [
        { key: 'parent.estructura.nombre', label: 'Elemento' },
        { key: 'estructura.nombre', label: 'Atributo' },
        { key: 'valor', label: 'Valor' },
        { key: 'is_required', label: 'Requerido' },
        { key: 'was_present', label: 'Presente' },
        { key: 'was_applied', label: 'Aplicado' },
        { key: 'is_valid', label: 'Válido' },
        { key: 'reglas_comprobante', label: 'Reglas' }
      ],
      bfilter: true,
      filter: 'No',
      filterOn: ['is_valid'],
      modalSeleccionPeriodo: false,
      modalSeleccionDepartamento: false,
      modalUploadPdf: false,
      settingPeriodo: false,
      settingDepartamento: false,
      uploadingPdf: false,
      isLoading: false,
      validations: [],
      loadingValidations: false,
      formValues: formValues(),
      response: response()
    }
  },

  props: {
    row: {
      required: true
    }
  },

  mounted () {
    this.init()
  },

  computed: {
    ...mapGetters('comprobantesModule', ['isFormFacturasValid']),

    numErrores () {
      return this.validations.filter((row) => (row.was_applied === 'Si' && row.is_valid === 'No') || ((row.is_required === 'Si' && row.was_present === 'No'))).length
    },

    isValid () {
      return !(this.numErrores > 0)
    },

    rowIsValid () {
      return !((this.row.item.is_valid === 'No') && (this.row.item.was_applied === 'Si')) || ((this.row.item.is_required === 'Si') && (this.row.item.was_present === 'No'))
    },

    filesNames () {
      return this.row.item.archivos.map((archivo) => archivo.filename).join(', ')
    },

    pdfIsPresent () {
      return this.row.item.archivos.map((archivo) => archivo.filename).some((filename) => filename.split('.').pop() === 'pdf')
    },

    xmlFile () {
      return this.row.item.archivos.filter((archivo) => archivo.filename.split('.').pop() === 'xml').map((archivo) => archivo.filename)[0]
    },

    selectedFile () {
      if (this.files.length === 1) {
        return this.files.map((file) => file.name)[0]
      }
      return ''
    },

    xmlFileName () {
      return this.xmlFile.split('.')[0]
    },

    selectedFileName () {
      return this.selectedFile.split('.')[0]
    },

    xmlFileExtension () {
      return this.xmlFile.split('.').pop()
    },

    selectedFileExtension () {
      return this.selectedFile.split('.').pop()
    },

    numFiles () {
      return this.files.length
    },

    isFormValid () {
      return this.files.length === 1 &&
        this.selectedFileExtension === 'pdf' &&
        this.selectedFileName === this.xmlFileName
    },

    files: {
      get () {
        return this.$store.state.comprobantesModule.importFacturas.files
      },
      set (value) {
        this.$store.commit('comprobantesModule/setFilesToImport', value)
      }
    }
  },

  methods: {
    async init () {
      this.loadingValidations = true
      const response = await this.$store.dispatch(`${STORE_MODULE}/getValidations`, this.row.item.id)
      this.validations = response.data
      this.loadingValidations = false
    },

    onValidateRow (id) {
      this.$store.dispatch(
        'comprobantesModule/validarFactura', { id }
      )
    },

    filterFunction (row, filter) {
      if (!filter || filter === '') {
        return false
      }
      return ((row.is_valid === 'No') && (row.was_applied === 'Si')) ||
        ((row.is_required === 'Si') && (row.was_present === 'No'))
    },

    onSelectPeriodo (concepto_id) {
      this.formValues.concepto_id = concepto_id
      this.modalSeleccionPeriodo = true
    },

    async onSelectDepartamento (concepto_id) {
      // Obtenemos la lista de departamentos de acuerdo al inversionista
      this.formValues.concepto_id = concepto_id
      this.modalSeleccionDepartamento = true
      await this.$store.dispatch('inversionistaModule/getSelectDeptos', this.row.item.inversionista.id)
    },

    onUploadPdf (concepto_id) {
      this.clearForm()
      this.formValues.concepto_id = concepto_id
      this.modalUploadPdf = true
    },

    resetSelectPeriodoForm () {
      document.activeElement.blur()
      this.$formulate.reset('selectPeriodoForm')
      this.formValues = formValues()
      this.response = response()
      this.modalSeleccionPeriodo = false
    },

    resetSelectDepartamentoForm () {
      document.activeElement.blur()
      this.$formulate.reset('selectDepartamentoForm')
      this.formValues = formValues()
      this.response = response()
      this.modalSeleccionDepartamento = false
    },

    clearForm () {
      this.$store.commit('comprobantesModule/setFilesToImport', [])
    },

    async submitSelectPeriodoForm () {
      this.settingPeriodo = true
      this.response = response()
      this.formValues.comprobante_id = this.row.item.id
      const { error, message, data } = await this.$store.dispatch(
        'comprobantesModule/setPeriodoConcepto', this.formValues
      )
      this.settingPeriodo = false
      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}
    },

    async submitSelectDepartamentoForm () {
      this.settingDepartamento = true
      this.response = response()
      this.formValues.comprobante_id = this.row.item.id
      const { error, message, data } = await this.$store.dispatch(
        'comprobantesModule/setDepartamentoConcepto', this.formValues
      )
      this.settingDepartamento = false
      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}
    },

    async submitUploadPdf () {
      this.uploadingPdf = true
      const response = await this.$store.dispatch(
        'comprobantesModule/importarPdf',
        {
          files: this.$store.getters['comprobantesModule/getImportFacturas'],
          comprobante_id: this.row.item.id
        }
      )
      this.response = response
      this.uploadingPdf = false
      this.modalUploadPdf = false
    },

    filterInvalidRules (rules) {
      return rules.filter((rule) => !rule.is_valid)
    }
  }
}
</script>

<style scoped>

</style>
